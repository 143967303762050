<template>
  <a-form
    class="form"
    ref="formRef"
    layout="vertical"
    :model="formState"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-form-item label="项目名称" >
      <a-input v-model:value="formState.name" placeholder="请输入项目名称" disabled/>
      <!-- <span style="padding-left: 20px;font-size: 16px;color: #888;">132</span> -->
    </a-form-item>
    <a-form-item label="展馆-展馆号-展区面积" name="region">
      <a-row>
        <a-col :span="6">
          <a-select v-model:value="formState.region" placeholder="请选择展馆号">
            <a-select-option value="shanghai">Zone one</a-select-option>
            <a-select-option value="beijing">Zone two</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="2" style="text-align: center;color: #ccc;">―—</a-col>
        <a-col :span="7"><a-input v-model:value="formState.name" placeholder="请输入展馆号"/></a-col>
        <a-col :span="2" style="text-align: center;color: #ccc;">―—</a-col>
        <a-col :span="7"><a-input v-model:value="formState.name" placeholder="请输入展位面积"/></a-col>
      </a-row>
    </a-form-item>
    <a-form-item  label="参展企业公司名称" name="name">
      <a-input v-model:value="formState.name" placeholder="输入面积"/>
    </a-form-item>
    <a-form-item label="参展商联系人" name="name">
      <a-input v-model:value="formState.name" placeholder="输入面积"/>
    </a-form-item>
    <a-form-item label="参展商负责人手机号" name="name">
      <a-input v-model:value="formState.name" placeholder="输入面积"/>
    </a-form-item>
    <a-form-item label="搭建企业公司全称">
      <a-input v-model:value="formState.name" placeholder="输入面积" disabled/>
    </a-form-item>
    <a-form-item  label="搭建商现场安全负责人" >
      <a-input v-model:value="formState.name" placeholder="输入面积" disabled/>
    </a-form-item>
    <a-form-item label="搭建商负责人手机号" >
      <a-input v-model:value="formState.name" placeholder="输入面积" disabled/>
    </a-form-item>
   
    <a-form-item label=" " style="text-align: center;">
      <a-button type="primary" @click="onSubmit">暂存</a-button>
      <a-button style="margin-left: 10px" @click="resetForm">下一步</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { defineComponent, reactive, ref, toRaw, onUpdated } from 'vue';
export default defineComponent({
  setup() {
    const formRef = ref();
    const formState = reactive({
      name: '',
      region: undefined,
      date1: undefined,
      delivery: false,
      type: [],
      resource: '',
      desc: '',
    });
    const rules = {
      name: [
        {
          required: true,
          message: 'Please input Activity name',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 5,
          message: 'Length should be 3 to 5',
          trigger: 'blur',
        },
      ],
      region: [
        {
          required: true,
          message: 'Please select Activity zone',
          trigger: 'change',
        },
      ],
      date1: [
        {
          required: true,
          message: 'Please pick a date',
          trigger: 'change',
          type: 'object',
        },
      ],
      type: [
        {
          type: 'array',
          required: true,
          message: 'Please select at least one activity type',
          trigger: 'change',
        },
      ],
      resource: [
        {
          required: true,
          message: 'Please select activity resource',
          trigger: 'change',
        },
      ],
      desc: [
        {
          required: true,
          message: 'Please input activity form',
          trigger: 'blur',
        },
      ],
    };

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          console.log('values', formState, toRaw(formState));
        })
        .catch(error => {
          console.log('error', error);
        });
    };

    const resetForm = () => {
      formRef.value.resetFields();
    };
    const song = ()=>{
      console.log(123);
    }
    onUpdated(()=>{
      console.log('移除one');
    })

    return {
      formRef,
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
      other: '',
      formState,
      rules,
      onSubmit,
      resetForm,
      song
    };
  },
});
</script>

<style lang="less" scoped>
.form{
  width: 50%;
  margin: auto;
}
</style>