<template>
  <a-row>
    <a-col :span="12">
      <p>效果图（正面、侧面、前视图；格式：jpg、png、jpeg；大小10M内）</p>
      <div class="newspaper-images-item">
        <my-upload/>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import MyUpload from '../../../components/smallParts/MyUpload.vue';
export default {
  components:{
    MyUpload
  },
  data(){
    return{
      list:[
        {
          name: '效果图',
          demand: ''
        }
      ]
    }
  }

}
</script>

<style scoped lang='less'>
  .newspaper-images-item{
    background-color: pink;
  }
</style>
