<template>
  <div>
    <!-- 已完成：finish 修改中：process 未修改：wait 有错误：error  （status="process"） -->
    <a-steps v-model:current="current" type="navigation" size="small" :style="stepStyle" @change="add">
      <a-step title="第一步" description="基本信息填写" />
      <a-step title="第二步" description="展台结构图、特证及材料上传" />
      <a-step title="第三步" description="缴费明细" />
    </a-steps>
    <!-- <div>
      <h2 style="text-align: center;font-size: 22px;">报馆基本信息（全部必填）</h2>
      <One ref="one" />
      <button @click="add">123131</button>
    </div> -->
    <div v-show="current==0">
      <h2>报馆基本信息(全部必填)</h2>
      <One ref="one"/>
    </div>
    <div v-show="current==1" >
      <h2>报馆资质、附件表格以及报馆图纸(必交)</h2>
      <Two ref="two"/>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import One from './template/one.vue'
import Two from './template/two.vue'
export default defineComponent({
  components: {
    One,
    Two
  },
  setup() {
    const current = ref(0);
    const listObj = ref([{
      name:'张三',
      region:'shanghai',
    },{}])

    const one = ref(null)
    const add = (a,b,c)=>{
      console.log(a,b,c);
      // one.value.song()
    }
    return {
      current,
      stepStyle: {
        marginBottom: '60px',
        boxShadow: '0px -1px 0 0 #e8e8e8 inset',
      },
      listObj,
      add,
      one
    };
  },
});
</script>
<style>
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description{
  max-width: none;
}
</style>
